import { ButtonHTMLAttributes } from 'react';
import './index.scss';
import './primary.scss';

type buttonDefaultProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonPrimary(props: buttonDefaultProps) {
    var { className } = props;
    var classRewriteName = 'btn btn-primary ' + (className || '');
    return (
        <button {...props} className={classRewriteName} onClick={(props?.onClick ? props.onClick : (ev) => { })} >
            {props?.children || ' - '}
        </button>
    );
}