import './index.scss';
import './default.scss';
import { ButtonHTMLAttributes } from 'react';

type buttonDefaultProps = ButtonHTMLAttributes<HTMLButtonElement>;
export function ButtonDefault(props: buttonDefaultProps) {
    var { className } = props;
    var classRewriteName = 'btn btn-default ' + (className || '');
    return (
        <button {...props} className={classRewriteName} onClick={(props?.onClick ? props.onClick : (ev) => { })} >
            {props?.children || ' - '}
        </button>
    );
}